import React, { useState } from 'react';
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';

import SubmitButton from 'components/atoms/submitButton';

const Wrapper = styled.form`
  width: 70%;
  padding: 5rem 2rem;
  border-radius: 2rem;
`;

const Label = styled.label`
  input,
  textarea {
    width: 100%;
    color: ${({ theme }) => theme.color.activebg};
    border: none;
    border-bottom: 2px solid ${({ theme }) => theme.color.activebg};
    outline: none;
    background: none;

    :focus {
      color: ${({ theme }) => theme.color.active};
      border-color: currentColor;
    }
  }

  textarea {
    height: 10rem;
  }
`;

const Form = () => {
  const [formState, setFormState] = useState({
    imie: '',
    email: '',
    wiadomosc: '',
  });

  const handleChange = e => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Wrapper
      name='contact'
      method='POST'
      action='/success'
      netlify-honeypot='bot-field'
      data-netlify-recaptcha='true'
      data-netlify='true'
    >
      <input type='hidden' name='form-name' value='contact' />
      <Label htmlFor='imie'>
        <input
          id='imie'
          type='text'
          name='imie'
          value={formState.imie}
          onChange={handleChange}
          maxLength='30'
          required
        />

        <p>Imię</p>
      </Label>
      <Label htmlFor='email'>
        <input
          id='email'
          type='email'
          name='email'
          value={formState.email}
          onChange={handleChange}
          maxLength='50'
          required
        />
        <p>email</p>
      </Label>
      <Label htmlFor='wiadomosc'>
        <textarea
          type='text'
          name='wiadomosc'
          value={formState.wiadomosc}
          onChange={handleChange}
          maxLength='500'
          minLength='50'
          required
        />
        <p>wiadomość</p>
      </Label>

      <ReCAPTCHA
        data-netlify-recaptcha='true'
        sitekey={process.env.GATSBY_SITE_RECAPTCHA_KEY}
      />

      <SubmitButton widthInRem='15' type='submit'>
        wyślij
      </SubmitButton>
    </Wrapper>
  );
};

export default Form;
