import React from 'react';
import styled from 'styled-components';

import SEO from 'components/utils/seo';
import Layout from 'components/utils/layout';
import GridView from 'components/utils/gridView';
import SocialMediaBox from 'components/modules/socialMediaBox';
import Form from 'components/organisms/form';
import HighlightedText from 'components/atoms/highlightText';

import EmailIcon from 'images/svg/email.svg';

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme: { mq } }) => mq.mediumDesktop} {
    padding: 0 5rem;
  }

  ${({ theme: { mq } }) => mq.tablet} {
    padding-top: 10rem;
  }
`;

const StyledHeading = styled.h2`
  text-align: center;
  padding: 5rem 10rem 0;

  ${({ theme: { mq } }) => mq.bigTablet} {
    padding: 10rem 10rem 0;
  }

  ${({ theme: { mq } }) => mq.tablet} {
    padding: 5rem 10rem 0;
    margin-bottom: -5rem;
  }
`;

const StyledContactWrapper = styled.div`
  display: flex;
  flex-direction: ${({ verticaly }) => (verticaly ? verticaly : 'row')};
  min-height: 40rem;
  width: 100%;
  margin: 5rem 0;
  h3 {
    text-align: center;
    font-size: ${({ theme: { fontSize } }) => fontSize.l};
  }

  ${({ theme: { mq } }) => mq.bigTablet} {
    flex-direction: column;
  }
`;

const ContactFormWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  ${({ theme: { mq } }) => mq.tablet} {
    form {
      width: 100%;
    }
  }
`;

const ContactDetailBox = styled.div`
  margin-top: 0;
  padding: 5rem 5rem;
  width: 50%;

  &:not(:last-child) {
    border-right: 2px solid ${({ theme: { color } }) => color.devider};
  }

  ${({ theme: { mq } }) => mq.bigTablet} {
    width: 100%;
    margin: 0 auto;
    border-bottom: 2px solid ${({ theme: { color } }) => color.devider};
    &:not(:last-child) {
      border-right: none;
    }
  }
  ${({ theme: { mq } }) => mq.bigPhone} {
    width: 100%;
  }
`;
const ContactDetailInfo = styled.h3`
  margin-bottom: 5rem;
`;

const StyledIconInfoBox = styled.div`
  display: flex;
  margin: 5rem 0;

  svg {
    fill: ${({ theme: { color } }) => color.darkFont};
    width: 3rem;
    height: 3rem;
    min-width: 3rem;
    margin: 0 2rem 0 0;
  }
`;

const ContactPage = () => (
  <Layout>
    <SEO title='Kontakt' image='logo' />
    <GridView>
      <StyledHeading>
        Masz pytanie? <HighlightedText>Skontaktuj</HighlightedText> się ze mną!
      </StyledHeading>
      <StyledSection>
        <StyledContactWrapper>
          <ContactDetailBox>
            <ContactDetailInfo>Złap mnie na social mediach!</ContactDetailInfo>
            <SocialMediaBox orientation='vertical' />
          </ContactDetailBox>
          <ContactDetailBox>
            <ContactDetailInfo>Napisz do mnie maila!</ContactDetailInfo>
            <StyledIconInfoBox>
              <EmailIcon />
              <p>koncepcjanasukces@gmail.com</p>
            </StyledIconInfoBox>
            <p>
              Odpowiadam na wszystkie widomości, ale czasami z delikatnym
              opóźnieniem. Wybacz jeżeli odpiszę po kilku dniach milczenia.
            </p>
          </ContactDetailBox>
        </StyledContactWrapper>

        <StyledContactWrapper verticaly='column'>
          <h3>
            Lub skorzystaj z <HighlightedText>formularza</HighlightedText>{' '}
            kontaktowego!
          </h3>
          <ContactFormWrapper>
            <Form />
          </ContactFormWrapper>
        </StyledContactWrapper>
      </StyledSection>
    </GridView>
  </Layout>
);

export default ContactPage;
